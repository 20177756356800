import { EditOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { Dispatch } from "react";
import { Customer } from "../../../models/Customer/customer.model";
import MailIcon from "./../../../assets/icons/multi color icons - svg/mail.svg";
import PhoneIcon from "./../../../assets/icons/multi color icons - svg/phone.svg";
import "./profileBasicDetailsCard.scss";

interface ProfileBasicDetailsCardProps {
  customer: Customer;
  openModal: Dispatch<boolean>;
}

const ProfileBasicDetailsCard = (props: ProfileBasicDetailsCardProps) => {
  const { customer, openModal } = props;
  const { firstName, middleName, lastName } = customer.kycBasicInfo || {};
  return (
    <div className="iprs-details">
      <div className="iprs-details__header">
        <p
          className={`iprs-details--title ${!customer.kycBasicInfo?.isFromIprs && "reduced-opacity"
            }`}
        >
          {customer.kycBasicInfo?.isFromIprs
            ? `${firstName?.toLowerCase() ?? ''} ${lastName?.toLowerCase() ?? ''} ${middleName?.toLowerCase() ?? ''}`
            : "Member Name"}
        </p>
        <p className="iprs-details--mail">
          <img src={MailIcon} alt="mail" /> {customer?.email}
        </p>
        <p className="iprs-details--phone">
          <img src={PhoneIcon} alt="call" /> {customer?.phone}
        </p>
        {(!!customer.kycBasicInfo?.isFromIprs || !!customer.registrationId) && <Tooltip title="Change Lookup ID">
          <EditOutlined
            className="modal-trigger-icon cursor-pointer"
            onClick={() => openModal(true)} />
        </Tooltip>}
      </div>
      {customer.kycBasicInfo?.isFromIprs ? (
        <div className="iprs-details__body">
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">Type</div>
            <div className="iprs-details--content-value">
              {customer?.role?.toLowerCase() || "-"}
            </div>
          </div>
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">Member ID</div>
            <div className="iprs-details--content-value">
              {customer?.uId || "-"}
            </div>
          </div>
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">Invitation Code</div>
            <div className="iprs-details--content-value">
              {customer?.inviteCode || "-"}
            </div>
          </div>
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">Member Since</div>
            <div className="iprs-details--content-value">
              {customer?.memberSince?.toLowerCase() || "-"}
            </div>
          </div>
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">ID Number</div>
            <div className="iprs-details--content-value">
              {customer?.kycBasicInfo?.kenyanNationalNumber?.toLowerCase() ||
                "-"}
            </div>
          </div>
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">Gender</div>
            <div className="iprs-details--content-value">
              {customer?.kycBasicInfo?.gender?.toLowerCase() || "-"}
            </div>
          </div>
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">DOB</div>
            <div className="iprs-details--content-value">
              {customer?.kycBasicInfo?.dateOfBirth?.toLowerCase() || "-"}
            </div>
          </div>
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">District of Birth</div>
            <div className="iprs-details--content-value">
              {customer?.kycBasicInfo?.districtOfBirth?.toLowerCase() || "-"}
            </div>
          </div>
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">Place of Issue</div>
            <div className="iprs-details--content-value">
              {customer?.kycBasicInfo?.placeOfIssue?.toLowerCase() || "-"}
            </div>
          </div>
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">Date of Issue</div>
            <div className="iprs-details--content-value">
              {customer?.kycBasicInfo?.dateOfIssue?.toLowerCase() || "-"}
            </div>
          </div>
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">District</div>
            <div className="iprs-details--content-value">
              {customer?.kycBasicInfo?.district?.toLowerCase() || "-"}
            </div>
          </div>
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">Division</div>
            <div className="iprs-details--content-value">
              {customer?.kycBasicInfo?.division?.toLowerCase() || "-"}
            </div>
          </div>
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">Location</div>
            <div className="iprs-details--content-value">
              {customer?.kycBasicInfo?.location?.toLowerCase() || "-"}
            </div>
          </div>
          <div className="iprs-details--content">
            <div className="iprs-details--content-title">Sub-location</div>
            <div className="iprs-details--content-value">
              {customer?.kycBasicInfo?.subLocation?.toLowerCase() || "-"}
            </div>
          </div>
        </div>
      ) : (
        <div className="iprs-details__empty">
          <p className="icon--container">
            <i className="icon-ic-info-24px"></i>
          </p>
          <p>Details Fetch Failed</p>
          <p
            className="modal-trigger cursor-pointer"
            onClick={() => openModal(true)}
          >
            Lookup ID
          </p>
          <p className="modal-trigger">
            KRA Pin: {customer?.kycBasicInfo?.KRAPinNumber}
          </p>
          <p className="modal-trigger">
            Kenyan National ID: {customer?.kycBasicInfo?.kenyanNationalNumber}
          </p>
        </div>
      )}
    </div>
  );
};

export default ProfileBasicDetailsCard;
